<!--
 * @Description: 批量开关房
 * @Author: 琢磨先生
 * @Date: 2022-10-29 11:17:58
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-10 17:43:19
-->
<template>
  <el-dropdown v-power="'seller/v1/roomState/close/batch'">
    <el-button>批量开/关房</el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="onOpen(0)">批量关房</el-dropdown-item>
        <el-dropdown-item @click="onOpen(1)">批量开房</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>

  <el-dialog
    :title="title"
    v-model="dialogVisible"
    width="800px"
    draggable
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="box">
          <div class="header_title">房间</div>
          <div class="box_body">
            <el-scrollbar>
              <el-tree
                ref="tree"
                :data="storeList"
                node-key="key"
                show-checkbox
                @check="checkChange"
              />
            </el-scrollbar>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box">
          <div class="header_title">
            <span>已选({{checkList.length}})</span>
            <el-button type="primary" link size="small" @click="onClear">清除</el-button>
          </div>
          <div class="box_body">
            <el-table :data="checkList" :show-header="false" height="300">
              <el-table-column label>
                <template #default="scope">{{scope.row.huxing}} {{scope.row.label}}</template>
              </el-table-column>
              <el-table-column label width="50">
                <template #default="scope">
                  <el-button
                    type="info"
                    icon="close"
                    size="small"
                    link
                    @click="onRemove(scope.row,scope.$index)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-form :model="form" ref="form" :rules="rules" label-position="top" style="margin-top:30px;">
      <el-form-item label="日期" prop="dates">
        <el-col :span="12">
          <el-date-picker
            v-model="form.dates"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="关房类型" prop="type" v-if="index==0">
        <el-radio-group v-model="form.type">
          <el-radio
            v-for="item in enums.store_close_types"
            :key="item.value"
            :label="item.value"
          >{{ item.text }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入备注内容（选填）"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      //是否有权限
      // is_power: false,
      dialogVisible: false,
      saving: false,
      title: "",
      index: 0,
      //带户型的房间
      storeList: [],
      //选中的值
      checkList: [],
      form: {
        type: 0,
      },
      rules: {
        dates: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["huxing_stores", "enums"],
  emits: ["success"],
  watch: {
    huxing_stores: {
      handler() {
        if (this.huxing_stores) {
          this.storeList = [];
          this.huxing_stores.forEach((item) => {
            var model = {
              key: `${item.id}`,
              id: item.id,
              label: item.name,
              children: [],
            };
            if (item.store_list) {
              item.store_list.forEach((x) => {
                model.children.push({
                  key: `${item.id}_${x.id}`,
                  id: x.id,
                  label: x.house_no,
                  huxing: item.name,
                  is_leaf: true,
                });
              });
            }
            this.storeList.push(model);
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadStore();
    // this.is_power = this.$power("seller/v1/roomState/close/batch");
  },
  methods: {
    /**
     * 打开弹框
     */
    onOpen(i) {
      this.title = i == 0 ? "批量关房" : "批量开房";
      this.index = i;
      this.dialogVisible = true;
    },
    checkChange(node, tree) {
      if (tree.checkedNodes) {
        this.checkList = tree.checkedNodes.filter((x) => x.is_leaf);
      }
    },
    onRemove(item, i) {
      this.checkList.splice(i, 1);
      this.$refs.tree.setChecked(item.key, false);
    },
    /**
     * 清除
     */
    onClear() {
      this.$refs.tree.setCheckedKeys([], false);
      this.checkList = [];
    },

    /**
     * 加载房态房间列表
     */
    loadStore() {
      this.$http.post("seller/v1/roomState/stores").then((res) => {
        if (res.code == 0) {
          this.storeList = [];
          res.data.forEach((item) => {
            var model = {
              key: `${item.id}`,
              id: item.id,
              label: item.name,
              children: [],
            };
            if (item.store_list) {
              item.store_list.forEach((x) => {
                model.children.push({
                  key: `${item.id}_${x.id}`,
                  id: x.id,
                  label: x.house_no,
                  huxing: item.name,
                  is_leaf: true,
                });
              });
            }
            this.storeList.push(model);
          });
        }
      });
    },

    /**
     * 提交
     */
    onSubmit() {
      if (this.checkList.length <= 0) {
        return;
      }
      this.form.store_ids = this.checkList
        .filter((o) => o.is_leaf)
        .map((x) => x.id);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post(
              `seller/v1/roomState/${this.index == 0 ? "close" : "open"}/batch`,
              this.form
            )
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.box {
  border: 1px solid var(--el-border-color);
}
.box .header_title {
  background: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0 10px;
  font-size: 16px;
  border-bottom: 1px solid var(--el-border-color);
}
.box .box_body {
  height: 300px;
}
</style>